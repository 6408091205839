const investmentTooltipSelector = '[data-investment-tooltip]'

document.addEventListener('click', function (e) {
    if (e.target.matches(investmentTooltipSelector)) {
        let domElem = e.target;
        if (domElem) {
            loadTootip(domElem)
        }
    }
});

async function loadTootip(elem) {
    let targetId = elem.getAttribute('data-target')
    let investmentId = elem.getAttribute('data-investment')
    let target = document.getElementById(targetId)

    if (!target || !investmentId) return
    if (target.classList.contains('is-loaded')) return

    target.classList.add('is-loading');

    const formData = new FormData();
    formData.append('investmentId', investmentId);
    
    const request = await fetch(
        `${home_url}/wp-json/mda-rest-api-frontend/investment-tooltip/get`,
        {
            method: 'POST',
            body: formData,
        }
    );
    const res = await request.json();
    
    if (res) {
        target.classList.remove('is-loading');
        target.classList.add('is-loaded');
        target.innerHTML = res.view
    }
}